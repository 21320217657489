@import url(https://fonts.googleapis.com/css2?family=Bungee&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Special+Elite&display=swap);
html,
body, #root {
  height: 100%;
  width: 100%;
  margin: 0px;
  position: absolute;
}

:root {
  --hour: 0deg;
  --minute: 0deg;
}

.App {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-rows: 1fr auto;
  font-family: "Helvetica";
  background-image: url("/pencil-1891732_1920.jpg");
}

.container {
  align-content: space-between;
  grid-row: 1;
  align-items: center;
}

.inner-container {
  margin: 0;
  max-width: 500px;
  height: 100%;
  background-color: rgb(255, 255, 255, 0.5);
  padding: 0 1%;
}

.header {
  width: 100%;
  font-family: "Bungee";
}

.letters {
  box-shadow: 2px 2px 1px 2px rgba(0, 0, 0, 0.4);
  padding: 0.3% 1%;
  margin: 8px 1%;
}

.header h1 {
  padding: 0;
  margin: 0;
  display: block;
}

.tilt {
  transform: rotate(5deg);
  background-color: #93f5fa;
}

.tilt-r {
  transform: rotate(-5deg);
  background-color: #37ff21;
}

.footer {
  width: 100%;
  justify-content: center;
  margin-top: auto;
  grid-row: 2;
  align-self: end;
  font-family: "Special Elite", cursive;
  padding: 3px 0;
}

.footer span {
  padding: 0 5px;
}

.footer a {
  color: black;
  text-decoration: none;
}

.footer a:hover {
  color: #ff3030;
}

.flex-container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
}

.centered {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.icons {
  max-width: 25px;
}

.card {
  align-self: center;
  width: 150px;
  height: 150px;
  box-shadow: 5px 6px 2px 5px rgb(0, 0, 0, 0.4);
  margin: 12px 3px 12px;
}

.space-between {
  justify-content: space-between;
}

.button-container {
  width: 33%;
}

.button-full {
  min-width: 100%;
}

.play-buttons {
  width: 100%;
  height: 40px;
  padding: 2px;
  margin: 1px 0px;
  background-color: #93f5fa;
}

input {
  width: 50px;
  margin: 2px;
  padding: 2px;
}

#white {
  background-color: #ffffff;
}

#red {
  background-color: #ff3030;
}

#green {
  background-color: #37ff21;
}

#response {
  margin: 2px;
  padding: 2px;
}

#scoreboard {
  width: 80%;
  justify-content: space-between;
  padding: 3px;
  border-style: inset;
}

#right {
  border-bottom: #37ff21 solid;
  padding: 1px 2px;
}

#wrong {
  border-bottom: #ff3030 solid;
  padding: 1px 2px;
}

.red {
  background-color: #ff3030;
}

#reset {
  background-color: #93f5fa;
  padding: 2px 7px;
}
.clock {
  width: 220px;
  height: 220px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 6px 2px 5px rgb(0, 0, 0, 0.4);
  margin: 12px 3px 12px;
}

#hour {
  width: 4px;
  height: 100px;
  position: absolute;
  z-index: 1;
  transform: rotate(0deg);
  transform: rotate(var(--hour));
}

#inner-hour {
  height: 50px;
  width: 4px;
  background-color: black;
  float: right;
}

#minute {
  width: 3px;
  height: 140px;
  position: absolute;
  z-index: 1;
  transform: rotate(0deg);
  transform: rotate(var(--minute));
}

#inner-minute {
  width: 3px;
  height: 70px;
  background-color: blue;
  float: right;
}

.answer {
  min-height: 50px;
}

.answer h2 {
  margin: 0 auto;
  padding: 10px 5px 0;
}

